import clsx from "clsx";
import { motion } from "framer-motion";
import { graphql, Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import React, { forwardRef } from "react";
import Logo from "../assets/icons/Logo";
import Background from "../components/Background";
import Footer from "../components/Footer";
import Seo from "../components/Seo";

const Homepage = forwardRef(({ data }, ref) => {
	const { home, pages } = data;
	const variantsGrid = {
		offscreen: { opacity: 1 },
		onscreen: {
			opacity: 1,
			transition: {
				staggerChildren: 0.1,
			},
		},
	};

	const variantsGridCard = {
		offscreen: {
			opacity: 0,
			scale: 1,
		},
		onscreen: {
			opacity: 1,
			scale: 1,
		},
	};

	return (
		<>
			<div
				className={
					"absolute w-screen flex flex-col justify-center items-center overflow-hidden top-0 left-0"
				}
			>
				<div className={"relative w-full h-screen flex justify-evenly"}>
					<div className={"absolute w-full h-full flex justify-center items-center "}>
						<div className={clsx("w-full p-8", "md:w-2/3 lg:w-1/2")}>
							<Logo colour={"#fcf6e7"} />
						</div>
					</div>
					<nav
						className={clsx(
							"w-full h-full flex items-end justify-center pb-8 z-10",
							"md:pb-16"
						)}
					>
						<div className={"flex flex-col gap-2 items-center"}>
							{home.menu.map((menu) => {
								const slug = pages.nodes.find(
									(x) => x.category.category === menu.category
								);
								return (
									<Link
										key={menu._id}
										to={`/${slug.prefixedSlug.current}`}
										className={
											"w-max text-xl text-cartridge hover:text-terracotta"
										}
										state={{ direction: "fromHome" }}
									>
										{menu.category}
									</Link>
								);
							})}
						</div>
					</nav>
				</div>
				<motion.div
					className={clsx(
						"w-full grid grid-cols-2 auto-rows-max gap-4 px-4 mt-16",
						"sm:grid-cols-3 md:grid-cols-4 md:px-16"
					)}
					initial={"offscreen"}
					whileInView={"onscreen"}
					variants={variantsGrid}
					viewport={{ once: true }}
				>
					{home.grid.map((grid) => {
						return (
							<motion.div
								key={grid._id}
								variants={variantsGridCard}
								className={"group relative aspect-square w-full overflow-hidden"}
							>
								<Link
									to={grid.visible ? `/${grid.prefixedSlug.current}` : "/"}
									className={"w-full h-full"}
									state={{ direction: "fromHome" }}
								>
									<div className={"w-full h-full"}>
										<div
											className={
												"absolute w-full h-full flex justify-center items-center text-center text-plaster text-3xl z-20 "
											}
										>
											{grid.title}
										</div>
										{grid?.hero && (
											<div
												className={
													"absolute w-full h-full z-10 top-0 left-0 opacity-0 group-hover:opacity-100 transition-opacity"
												}
												style={{
													background:
														grid.hero.asset.metadata.palette.dominant
															.background,
												}}
											/>
										)}
										<div className={"relative w-full h-full"}>
											{grid?.hero && (
												<Image
													{...grid.hero}
													alt={grid.hero.alt || ""}
													width={500}
													style={{
														width: "100%",
														height: "100%",
														objectFit: "cover",
													}}
												/>
											)}
										</div>
									</div>
								</Link>
							</motion.div>
						);
					})}
				</motion.div>
				<Footer />
			</div>
			{home.background && <Background background={home.background} />}
		</>
	);
});

export default Homepage;

export const Head = () => <Seo />;

export const query = graphql`
	query getMenuItem($menu: [String]) {
		pages: allSanityPage(
			filter: { category: { category: { in: $menu } } }
			sort: { order: ASC, fields: orderRank }
		) {
			nodes {
				category {
					category
				}
				prefixedSlug {
					current
				}
			}
		}
		home: sanityHomepage {
			menu {
				_id
				category
			}
			grid {
				_id
				title
				prefixedSlug {
					current
				}
				hero {
					alt
					...Image
					asset {
						metadata {
							palette {
								dominant {
									background
								}
							}
						}
					}
				}
				visible
			}
			background {
				... on SanityColour {
					_type
					colour
				}
				... on SanityTexture {
					_type
					image {
						asset {
							url
							metadata {
								dimensions {
									height
									width
								}
								palette {
									dominant {
										background
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
